import React, {useState, useEffect} from "react"
import "./SignupCard.scss"

const SignupCard = (props) => {
    const [signUp, setSignUp] = useState(null);
    useEffect(() => {
        setSignUp(props.signUpCard)
    }, [props]);
    const request = () => {
        window.open(process.env.GATSBY_CALENDLY_URL , "_blank")
    }
    return (
        <section className="signupcard--wrap">
            {signUp && <section className="signupcard signupcard--container">
                <h2 className="signupcard--heading">{signUp.heading}</h2>
                <p className="signupcard--subcontent">{signUp.subHeading}</p>
                <button className="signupcard--button" onClick={request}>{signUp.button}</button>
            </section>}
        </section>
    )
}
export default SignupCard