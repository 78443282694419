import React from "react"
import "./FeatureCon.scss"

const FeatureCon = (props) => {
    return (
        <section className={`featurecon ${props.featureconclass ? props.featureconclass : ""}`}>
            <img src={`/${props.featureImage}.svg`} alt={props.alt} className={`featurecon--icon ${props.className}`} />
            <h6 className="featurecon--heading">{props.heading}</h6>
            <p className="featurecon--subcontent" dangerouslySetInnerHTML={{ __html: props.subHeading }}></p>
        </section>
    )
}
export default FeatureCon