import React from 'react';
import Modal from 'react-modal';

const ModalComponent = ({ isOpen, onRequestClose, url }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="YouTube Video Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          width: "90%"
        },
      }}
    >
      <div style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
       <button onClick={onRequestClose}><img src="/close.svg" alt="close"/></button>
      </div>
      <div style={{ position: 'relative', width: "100%"}}>
        <iframe
            style={{
              width: "100%",
              height: "500px",
              borderRadius: "20px"
            }}
            type="text/html"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Video"
        ></iframe>
      </div>
    </Modal>
  );
};

export default ModalComponent;
