import React from 'react';
import Slider from 'react-slick';
import "./Recognition.scss"

const Recognition = () => {

  const recognitions = [
    { id: 1, title: 'Microsoft Founders Club', image: '/MS-Startups.png', className: "MS-Startups" },
    { id: 2, title: 'StartEd Member', image: '/StartEd.png', className: "StartEd" },
    { id: 3, title: 'EDUCAUSE Under the radar pitch Finalist ', image: '/educause.jpeg', className: "educause" },
    { id: 4, title: 'DC StartupWeek Finalist', image: '/DCStartupWeek.png', className: "DCStartupWeek" },
    { id: 5, title: 'AWS EdStart Member', image: '/Edalex-AWS-EdStart.jpg', className: "Edalex-AWS-EdStart" },
    { id: 6, title: 'Transcend Founder Network', image: '/transcend.jpeg', className: "transcend" },
    { id: 7, title: 'EdTech Recharge Rally Participant', image: '/edTechRecharge.png', className: "EdTechRecharge" },
  ];

  return (
    <div className="recognition">
      <h3 className="recognition--heading">Recognitions</h3>
      <div className='recognition-wrap'>
        {recognitions.map((recognition) => (
          <div key={recognition.id} className="recognition-slide">
              <img src={recognition.image} alt={recognition.image} className={recognition.className}/>
              <p>{recognition.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recognition;
