import React, { useState,useEffect } from "react"
import FeatureCon from "../FeatureCon"
import "./FeatureDynos.scss"

const FeatureDynos = (props) => {
    const [featureHead, setFeatureHead] = useState(null);
    const [featureContent, setFeatureContent] = useState(null);
    useEffect(() => {
        setFeatureHead(props.featureDynoHead)
        setFeatureContent(props.featureDynoContent)
    }, [props]);
    return (
        <section className="featuredynos-wrap">
            <section className="featuredynos featuredynos--container">
                {featureHead && <h3 className="featuredynos--heading">{featureHead.heading}</h3>}
                <section className="featurecon--wrap">{featureContent && featureContent.map(({heading,subHeading,featureImage,alt},key) =>
                    <FeatureCon key={key} heading={heading} subHeading={subHeading} featureImage={featureImage} alt={alt} featureconclass="featureconclass" className="icon--width"/>
                )}</section>
            </section>
        </section>
    )
}
export default FeatureDynos