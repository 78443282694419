import React, {useState, useEffect} from "react"
import Slider from 'react-slick';
import GifContent from "../GifContent"
import "./DynosFeatureTab.scss"
import "../../ui-lib/styles/slick.scss"
import "../../ui-lib/styles/slick-theme.scss"

const SlickButton = ({currentSlide, slideCount, children, ...props}) => (
    <button type="button" {...props}>{children}</button>
  );

const DynosFeatureTab = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 3000,
        fadeIn: false,
        // autoplay: true,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickButton><img src="/carousel-back.svg" alt="carousel-back" /></SlickButton>,
        nextArrow: <SlickButton><img src="/carousel-forward.svg" alt="carousel-forward" /></SlickButton>,
      };

    const [gifcontent, setGifcontent] = useState(null);
    const [gifButtonContent, setGifButtonContent] = useState(null);
    useEffect(() => {
        setGifcontent(props.gifContents)
        setGifButtonContent(props.gifButtonContent)
    }, [props]);
    
    const setTab = (id) => {
        const tabContent = gifcontent.map((item) => ({...item, show: item.id === id}))
        const gifbtn = gifButtonContent.map((item) => ({...item, active: item.id === id}))
        setGifcontent(tabContent)
        setGifButtonContent(gifbtn)
    }
    return(
        <section className="dynosfeaturetab--wrap">
            <section className="dynosfeaturetab dynosfeaturetab--container">
                <section className="dynosfeaturetab--button-wrap">
                    <section className="dynosfeaturetab--mobile">
                        <Slider {...settings}>
                            {gifcontent && gifcontent.map(({gif,gifcontent,show,id,alt,button}) =>
                                <section key={id}>
                                    <h3>{button}</h3>
                                    <GifContent className="tabcontent--show-mobile" alt={alt} gifcontent={gifcontent} gif={gif} />
                                </section>
                            )}
                        </Slider>
                    </section>
                    <section className="dynosfeaturetab--desktop">
                        {gifButtonContent && gifButtonContent.map(({active,id,button}) =>
                            <button key={id} onClick={() => setTab(id)} className={`dynosfeaturetab--button ${active ? "active" : ""}`}>{button}</button>
                        )}
                    </section>
                </section>
                    {gifcontent && gifcontent.map(({gif,gifcontent,show,id,alt}) =>
                        <GifContent key={id} className={ show ? "tabcontent--show" : ""} alt={alt} gifcontent={gifcontent} gif={gif} />
                    )}
            </section>
        </section>
    )
}
export default DynosFeatureTab