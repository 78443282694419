import React, {useState, useEffect} from 'react'
import "./Modal.scss"

const Modal = ({children,openModal,close}) => {
    const [modal, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
        close()
    }
    useEffect(() => {
        setModal(openModal)
    }, [openModal])
    return (
        <React.Fragment>
            {
                modal && 
                <section className="modal--wrap">
                    {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
                    <section onClick={closeModal} onKeyPress={closeModal} role="button" aria-label="overlay" className="modal--overlay"></section>
                    <section className="modal--content">
                        <section className="modal--body">
                            <button onClick={closeModal}><img src="/close.svg" alt="close"/></button>
                            {children}
                        </section>
                        {/* <section className="modal--footer">
                            <button onClick={closeModal} className="modal--confirm">Ok</button>
                        </section> */}
                    </section>
                </section>
            }
            
        </React.Fragment> 
    )
}
export default Modal 
