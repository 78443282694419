import React, { useState } from 'react';
import ModalComponent from '../ModalComponent';
import "./Testimonial.scss"

const Testimonial = ({url, thumbnail}) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const openModal = () => {
      setModalIsOpen(true);
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
    };

    return (
        <section className="testimonial">
            <section className="testimonial--container">
                <div className="thumb" onClick={openModal}>
                    <img src={thumbnail} />
                    <div className="thumb-icon">
                        <img src="/youtube.png" />
                    </div>
                </div>
                <ModalComponent isOpen={modalIsOpen} onRequestClose={closeModal} url={url} />
            </section>
        </section>
    )
}
export default Testimonial