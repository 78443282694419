import React from "react"
import Slider from 'react-slick';
import Testimonial from "../Testimonial"
import "../../ui-lib/styles/carousel.scss"
import "../../ui-lib/styles/slick.scss"
import "../../ui-lib/styles/slick-theme.scss"

const SlickButton = ({currentSlide, slideCount, children, ...props}) => (
  <button type="button" {...props}>{children}</button>
);

const TestimonialCarousel = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        fadeIn: false,
        autoplay: false,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickButton><img src="/carousel-back.svg" alt="carousel-back" /></SlickButton>,
        nextArrow: <SlickButton><img src="/carousel-forward.svg" alt="carousel-forward" /></SlickButton>,
      };
    return (
        <section className="carousel">
            <h3 className="carousel--heading">Testimonials</h3>
            <Slider {...settings}>
                {props.testimonial.map(({url, thumbnail},key)=> <Testimonial key={key} url={url} thumbnail={thumbnail}/>)}
            </Slider>
        </section>
    )
}
export default TestimonialCarousel