import React from "react"
import "./GifContent.scss"

const GifContent = (props) => {
    return (
        <section className={`gifcontent ${props.className}`}>
            <p>{props.gifcontent}</p>
            <section className="gifcontent--gif">
                <img src={`/${props.gif}.png`} alt={props.alt}/>
            </section>
        </section>
    )
}
export default GifContent       