import React, {useState} from "react"
import HomeBanner from "../components/Banner/HomeBanner"
import Layout from "../layouts/layout"
import FeatureDynos from "../components/FeatureDynos"
import SignupCard from "../components/SignupCard"
import DynosFeatureTab from "../components/DynosFeatureTab"
import TestimonialCarousel from "../components/TestimonialCarousel"
import ContentHeadingPara from "../components/ContentHeadingPara"
import Header from "../components/Header"
import SEOPage from "../components/SEOPage"
import Recognition from "../components/Recognition"
import Modal from "../components/Modal"
import "../ui-lib/styles/Main.scss"

const Home = () => {

  const [openModal, setOpenModal] = useState(false)

  const banner = {
    heading: "Empower your school with Dynos digital learning!",
    subHeading: "Dynos provides a comprehensive digital learning solution for schools, including engaging digital content for all subjects and an integrated learning management system with guided lesson plans.",
    button: "Get Started",
    image: "banner-graphic"
  }
  const gifButtonContent =[
    {
        id: 1,
        button: "Dynos Comprehensive Lesson Plans",
        active: true
    },
    {
        id: 2,
        button: "Dynamic Digital Content",
        active: false
    },
    {
        id: 3,
        button: "NCF Aligned Content",
        active: false
    },
    {
        id: 4,
        button: "Dynos School Operating System (LMS & ERP)",
        active: false
    },
    {
        id: 5,
        button: "Teacher Training",
        active: false
    }
    ]
  const gifContents = [
    {
        id: 1,
        gif: "comprehensive1",
        button: "Dynos Comprehensive Lesson Plans",
        gifcontent: "Experience holistic learning with Dynos' Comprehensive Lesson Plans, featuring interactive resources and insightful guidance for students of all levels.",
        show: true,
        alt: "Lesson Plans"
    },
    {
        id: 2,
        gif: "digital1",
        button: "Dynamic Digital Content",
        gifcontent: "Dynos' Dynamic Digital Content enhances critical thinking with engaging what-if scenarios and offers personalized learning through self-graded quizzes on any device.",
        show: false,
        alt: "dynamic digital content"
    },
    {
        id: 3,
        gif: "ncf1",
        button: "NCF Aligned Content",
        gifcontent: "Dynos offers a curriculum in print and digital formats, aligned with the National Curriculum Framework, to instill values, capacities, and knowledge.",
        show: false,
        alt: "NCF aligned content"
    },
    {
        id: 4,
        gif: "lmserp1",
        button: "Dynos School Operating System (LMS & ERP)",
        gifcontent: "Digitize your institution with Dynos School Operating System, combining LMS and ERP to streamline administration and optimize teaching and learning.",
        show: false,
        alt: "LMS & ERP"
    },
    {
        id: 5,
        gif: "training1",
        button: "Teacher Training",
        gifcontent: "Our expert team provides tailored onboarding and teacher training, empowering educators with innovative methodologies and technological proficiency for vibrant digital classrooms.",
        show: false,
        alt: "teacher training"
    }
  ]

  const dynotypeHead = {
    heading: "Paving the way for a vibrant digital classroom environment",
    subHeading: "Discover a transformative approach to education with Dynos' comprehensive suite of offerings. From effective lesson plans aligned with national standards to dynamic digital content fostering critical thinking, Dynos provides educators and students alike with tools for engaging, personalized learning experiences. Explore how Dynos' School Operating System streamlines administrative tasks while empowering teachers through tailored training programs, paving the way for a vibrant digital classroom environment. "
  }

  const testimonial = [
    {
        thumbnail: "/testimonial1.png",
        url: "https://www.youtube.com/embed/tq9WIgKO8Jk?autoplay=1&controls=0&disablekb=1&rel=0"
    },
    { 
       thumbnail: "/testimonial2.png",
       url: "https://www.youtube.com/embed/2nTSn_RMlUw?autoplay=1&controls=0&disablekb=1&rel=0"
    },
    {   
        thumbnail: "/testimonial3.png",
        url: "https://www.youtube.com/embed/Mp9nVpgy9bk?autoplay=1&controls=0&disablekb=1&rel=0"
    },
    {
       thumbnail: "/testimonial4.png",
       url: "https://www.youtube.com/embed/5mTWzEMrgcQ?autoplay=1&controls=0&disablekb=1&rel=0"
    },
]
  const featureDynoHead = {
    heading: "We care about helping all students learn in a safe digital environment.",
  }
  const featureDynoContent = [
    {
        heading: "Privacy",
        subHeading: `We pledge to protect students, teachers and all our users’ privacy at all costs. Please read our <a href="/privacy" style="font-weight: 500; color: #00b0ff">Privacy Policy</a> for more details.`,
        featureImage: "privacy",
        alt: "privacy"
    },
    {
        heading: "Accessibility",
        subHeading: `We are dedicated to make education accessible to everyone. We are striving to comply with WCAG accessibility standards. Please read our <a href="/accessibility" style="font-weight: 500; color: #00b0ff">Accessibility Policy</a> for more details.`,
        featureImage: "accessibility",
        alt: "accessibility"
    }
  ];
  const signUpCard = {
    heading: "Empower your students with Dynos",
    subHeading: "Discover the complete digital learning solution with Dynos. From interactive content to user-friendly management tools, we've got you covered. Contact us today to schedule a no-obligation demo and see how Dynos can transform your learning experience.",
    button: "Request a Demo"
  }

  return (
    <Layout>
      <section className="main--wraper">
        <SEOPage 
            title="Dynos | Providing Affordable Engaging Digital Learning Solutions to Schools"
            description = "Dynos empowers schools to create engaging digital classrooms through interactive content, integrated lesson plans, and a user-friendly learning management system."
            image = "/homeMetaImage.png"
        />
        <Header className="home" fill1="#fff" fill2="#fff"/>
        <section className="main--container">
          <HomeBanner heading={banner.heading} subHeading={banner.subHeading} button={banner.button} image={banner.image}  color="white" />
        </section>
      </section>
      <section className="main--container">
        <section className="align--center mt-120 padding-mobile">
          <ContentHeadingPara heading={dynotypeHead.heading} subHeading={dynotypeHead.subHeading} />
        </section>
      </section>
      <section className="youtube-container align-center" onClick={() => setOpenModal(true)}>
        <img src={"/main-video.png"} />
        <div className="thumb-icon">
            <img src="/youtube.png" />
        </div>
      </section>
      <DynosFeatureTab gifButtonContent={gifButtonContent} gifContents={gifContents} />
      <Recognition />
      <section className="bg padding-10">
        <TestimonialCarousel testimonial={testimonial} />
        <FeatureDynos featureDynoContent={featureDynoContent} featureDynoHead={featureDynoHead} />
        <SignupCard signUpCard={signUpCard} />
      </section>
      <Modal openModal={openModal} close={() => setOpenModal(false)}>
        <section style={{ margin: "25px 0", width: "100%" }}>
          <iframe
            src="https://www.youtube.com/embed/RzKP-jLPUQg?autoplay=1&controls=0&disablekb=1&rel=0"
            style={{
              width: "100%",
              height: "500px",
              borderRadius: "20px"
            }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Video"
          ></iframe>
        </section>
      </Modal>
    </Layout>
  )
}
export default Home